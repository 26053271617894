.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}
.ant-picker-calendar-date{
  padding: 0px !important;
  margin: 4px 8px 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  position: static;
  width: auto;
  height: 20px;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  text-align: left;
}
