
.ant-popover-message-title{
padding: 0 10px;
}
.ant-popover-buttons button{
  margin-left: 0 !important;
}
.ant-popover-buttons{
  display: flex;
  justify-content: space-between;
}