@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.15);
}
.ck-content ul {
  padding-left: 15px;
}
.ant-row {
  margin-bottom: 10px;
}
.ant-btn {
  font-weight: 400;
}
.ant-dropdown-menu {
  padding: 0;
}
.file-item:hover {
  text-decoration: underline;
}
.ant-select-tree-title {
  color: black;
}
.ant-row-pointer {
  cursor: pointer;
}
.ant-disabled {
  background: #ffffff !important;
  color: #000000d9 !important;
  cursor: not-allowed;
}
.ant-select-tree-node-content-wrapper-open .ant-select-tree-title {
  font-weight: bold;
}
.wrapper-list .ant-popover-inner-content {
  padding: 0;
}
.ant-select-item-option-content {
  white-space: unset;
  text-overflow: unset;
}

.ant-popover-message-title:has(.custom-popconfirm) {
  padding-left: 20px !important;
}

.ant-popover-buttons:has(button .custom-button-popconfirm) {
  display: flex;
  justify-content: flex-end !important;
  grid-gap: 8px !important;
  gap: 8px !important;
}

.MentionTextInput_mentions__1grDQ {
    background-color: #f0f2f5;
    border: 1px solid #dedbdb;
    border-radius: 8px;
}

.MentionTextInput_readonly__1N3HW {
    pointer-events: auto;
    border: none;
}

.MentionTextInput_mentions__1grDQ textarea {
    border: none;
}

.MentionTextInput_mentions--singleLine__3N09r .MentionTextInput_mentions__control__14GWV {
    display: inline-block;
    width: 130px;
}

.MentionTextInput_mentions--singleLine__3N09r .MentionTextInput_mentions__highlighter__16Ebh {
    padding: 1px;
    border: 2px inset transparent;
}

.MentionTextInput_mentions--singleLine__3N09r .MentionTextInput_mentions__input__1AmAe {
    padding: 1px;
    border: 2px inset;
}

.MentionTextInput_mentions--multiLine__4r9O0 .MentionTextInput_mentions__control__14GWV {
    /*font-family: monospace;*/
    font-size: 1.2em;
}

.MentionTextInput_mentions--multiLine__4r9O0 .MentionTextInput_mentions__highlighter__16Ebh {
    border: 1px solid transparent;
    padding: 9px;
    /*min-height: 63px;*/
}

.MentionTextInput_mentions--multiLine__4r9O0 .MentionTextInput_mentions__input__1AmAe {
    border: 1px solid transparent;
    padding: 9px;
    outline: 0;
}

.MentionTextInput_mentions__input__1AmAe textarea {
    border: none;
}

.MentionTextInput_mentions__suggestions__list__1xcj2 {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 10pt;
}

.MentionTextInput_mentions__suggestions__item__1Y3yx {
    padding: 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.MentionTextInput_mentions__suggestions__item--focused__2L1m0 {
    background-color: #cee4e5;
}

.MentionTextInput_mentions__mention__125XO {
    position: relative;
    z-index: 1;
    color: blue;
    text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white;
    text-decoration: underline;
    pointer-events: none;
}

.ant-table-wrapper.custom-table-timekeeping .ant-table-sticky-scroll {
    background: none !important;
    border-top: none !important;
}
.ant-table-wrapper.custom-table-timekeeping .ant-table-sticky-scroll .ant-table-sticky-scroll-bar {
    width: 0 !important;
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}
.ant-picker-calendar-date{
  padding: 0px !important;
  margin: 4px 8px 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  position: static;
  width: auto;
  height: 20px;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  text-align: left;
}


.ant-popover-message-title{
padding: 0 10px;
}
.ant-popover-buttons button{
  margin-left: 0 !important;
}
.ant-popover-buttons{
  display: flex;
  justify-content: space-between;
}
.disabled-row-table, .disabled-row-table .ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
    opacity: 0.5 !important;
    background-color: #f2f2f2 !important;
    cursor: not-allowed !important;
}

.ant-popover-message-title .popconfirm-custom-padding {
    padding-left: 10px !important;
}

.customCssRow {
    opacity: 0.5;
    cursor: not-allowed !important;
  }

