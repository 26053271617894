.disabled-row-table, .disabled-row-table .ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
    opacity: 0.5 !important;
    background-color: #f2f2f2 !important;
    cursor: not-allowed !important;
}

.ant-popover-message-title .popconfirm-custom-padding {
    padding-left: 10px !important;
}

.customCssRow {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
